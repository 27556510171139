import { isEmpty, map } from "lodash";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import setCountryCode from "../../actions/currencyPicker/setCountryCode";

const CurrencyPicker = ({ actions, countryCode }) => {
  const [showCurrencyPicker, setShowCurrencyPicker] = useState(false);

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (typeof wcCurrencyNonce !== "undefined") {
    // console.log("found the nonce", wcCurrencyNonce);
    headers.append("X-WP-Nonce", wcCurrencyNonce);
  }

  useEffect(() => {
    if (Cookies.get("currency_country")) {
      actions.setCountryCode(Cookies.get("currency_country"));
      setShowCurrencyPicker(true);
    } else {
      setTimeout(() => {
        fetch("/wp-json/country-picker/destination", {
          method: "GET",
          headers: headers,
          credentials: "include",
        })
          .then((response) => response.json())
          .then((destination) => {
            console.log("destination", destination);
            actions.setCountryCode(destination.countryCode);
            Cookies.set("currency_country", destination.countryCode, {
              path: "/",
              expires: 7,
            });
            setShowCurrencyPicker(true);
          })
          .catch((err) => console.error(err));
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (countryCode) {
      // console.log('countryCode changed to', countryCode)

      const approxPriceWrapper = document.querySelectorAll(
        ".approx-price-wrapper"
      );
      if (approxPriceWrapper) {
        Array.from(approxPriceWrapper).map((aPW, index) => {
          const hiddenInputField = aPW.querySelector(
            ".approx-price-hidden-field"
          );

          const data = hiddenInputField.value;
          const type = hiddenInputField.dataset.type;
          const shipping = hiddenInputField.dataset.shipping;

          const approxPriceElement = aPW.querySelector(".price.price--approx");

          const body = {
            countryCode: countryCode,
            ...(data && { data: data }),
            ...(type && { type: type }),
            ...(shipping && { shipping: shipping }),
          };

          fetch("/wp-json/country-picker/price", {
            method: "POST",
            credentials: "include",
            headers: headers,
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((approxPrice) => {
              if (!approxPrice) {
                approxPriceElement.textContent = "";
              } else if (approxPrice.code) {
                approxPriceElement.textContent = "";
              } else {
                approxPriceElement.textContent = `Approx. ${approxPrice}`;
              }
            });
        });
      }
    }
  }, [countryCode]);

  const updateCountry = (countryCode) => {
    const storedCountry = Cookies.get("currency_country");
    const prevCountryCode = updateCountry.prevCountryCode || null;

    if (countryCode && countryCode !== prevCountryCode && countryCode !== storedCountry) {
      updateCountry.prevCountryCode = countryCode;

      fetch("/wp-json/country-picker/destination", {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ countryCode }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to post countryCode: ${response.statusText}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Response data", data);

          actions.setCountryCode(data.countryCode || countryCode);
          Cookies.set("currency_country", data.countryCode, {
            path: "/",
            expires: 7,
          });
          refreshTopBar();
        })
        .catch((error) => {
          console.error("Error updating countryCode", error);
        });
    }
  };

  const refreshTopBar = () => {
    fetch("/wp-json/country-picker/top-bar-message", {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch top bar content: " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const topBar = document.getElementById("top-bar-text");
        if (topBar) {
          topBar.innerHTML = data?.message;
        } else {
          console.error("Top bar element not found!");
        }
      })
      .catch((error) => {
        console.error("Error refreshing the top bar:", error);
      });
  };

  // updateCountryCode @event is executed from
  // @file checkout.js in updateBillingCountry function
  // this way we can update current value based on selected country
  // on the checkout form
  useEffect(() => {
    const handleUpdateCountryCode = (event) => {
      const { countryCode } = event.detail;
      console.log("Received updated countryCode:", countryCode);

      Cookies.set("currency_country", countryCode, { path: "/", expires: 7 });
      actions.setCountryCode(countryCode);
    };

    document.addEventListener("updateCountryCode", handleUpdateCountryCode);

    return () => {
      document.removeEventListener("updateCountryCode", handleUpdateCountryCode);
    };
  }, []);

  const handleChange = (e) => {
    console.log("handleChange", e.target.value);
    actions.setCountryCode(e.target.value);
    updateCountry(e.target.value);
    Cookies.set("currency_country", e.target.value, { path: "/", expires: 7 });
  };

  return (
    <>
      {showCurrencyPicker && countryCode && (
        <div className="country-picker-wrapper relative flex items-center">
          <p
            className="m-0 font-body-regular text-sm text-black tracking-wide"
            dangerouslySetInnerHTML={{ __html: countries[countryCode] }}
          />
          <div
            id="country-picker-flag"
            className={`flag flag-icon-${countryCode.toLowerCase()}`}
          />
          <select
            value={countryCode}
            onChange={(e) => handleChange(e)}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 opacity-0 z-0"
          >
            {!isEmpty(countries) &&
              map(countries, (country, index) => (
                <option
                  key={index}
                  value={index}
                  dangerouslySetInnerHTML={{ __html: country }}
                />
              ))}
          </select>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setCountryCode,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

const mapStateToProps = (state) => {
  const props = {
    countryCode: state.currencyPicker.countryCode,
  };

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyPicker);
